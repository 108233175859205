

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;


}

.header {
  color: whitesmoke;
  margin-top: 200px;
}

.text {
  color: whitesmoke;
  margin-top: 400px;
  
}